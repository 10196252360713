import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import style from './applyforjob.module.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import Swal from 'sweetalert2';
import LoderPageRound from '../Loading/LoderPageRound';

const APPLY_JOB_SCHEMA = Yup.object().shape({
    FirstName: Yup.string()
        .required('First name is required !')
        .min(2, 'First name must be at least 2 characters !')
        .max(50, 'First name cannot exceed 50 characters !'),
    LastName: Yup.string()
        .required('Last name is required !')
        .min(2, 'Last name must be at least 2 characters !')
        .max(50, 'Last name cannot exceed 50 characters !'),
    Email: Yup.string().email('Invalid email').required('Email is required !'),
    MobileNo: Yup.string()
        .required('Mobile no is required !')
        .test('len', 'Mobile no must be 10 digits', (value) => {
            return value.toString().length === 10;
        })
        .matches(/^[0-9]{10}$/, 'Invalid mobile number format !'),
    City: Yup.string()
        .required('City is required !')
        .min(2, 'City must be at least 2 characters !')
        .max(50, 'City cannot exceed 50 characters !')
        .matches(/^[a-zA-Z\s]+$/, 'City can only contain letters !'),
    Experience: Yup.string().required('Select experience it is required !'),
    ResumeOrCv: Yup.mixed()
        .required("Please upload Resume / Cv in PDF format it is required !")
        .test("fileType", "Only PDF file are allowed it is required !", (value) => {
            if (!value) return true;
            return value.name.endsWith(".pdf");
        })
        .test("fileSize", "File size is too large. Maximum allowed File size is 1MB.", (value) => {
            if (value) {
                return value.size <= 1048576; // 1MB
            }
            return true;
        }),
    Position: Yup.string().required('Select position it is required !'),
    Joining: Yup.string().required('Select joining it is required !'),
    Messages: Yup.string()
        .required('Message is required !')
        .min(10, 'Message must be at least 10 characters !')
        .max(500, 'Message cannot exceed 500 characters !')

});

const ApplyForJob = ({ showModal, closeModal, selectedJobId }) => {

    let [loader, Setloader] = useState(false);

    if (loader) {
        return <LoderPageRound />;
    }

    return (
        <div>
            <Modal show={showModal} onHide={closeModal} aria-labelledby="contained-modal-title-vcenter" centered className='ps-0' >
                <Modal.Header className='pt-lg-3'>
                    <button className={`btn p-0 border-0 bg-white ${style.closebtn}`} onClick={closeModal}>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.50041 8.03102L0.598557 2.12915C0.598556 2.12915 0.598555 2.12915 0.598554 2.12915C0.175483 1.70609 0.175482 1.02016 0.598552 0.597092C0.598554 0.59709 0.598555 0.597089 0.598557 0.597087M6.50041 8.03102L1.95384 0.773866C1.62841 0.448425 1.10076 0.448425 0.775331 0.773866L0.598557 0.597087M6.50041 8.03102L0.598572 13.9328L0.598555 13.9328C0.175504 14.356 0.175504 15.0418 0.598555 15.465L0.598576 15.465C1.02164 15.888 1.70757 15.888 2.13063 15.465L2.13063 15.465L8.03246 9.56308L13.9344 15.465C14.3574 15.888 15.0434 15.888 15.4664 15.465L15.4664 15.4649C15.8894 15.0418 15.8895 14.356 15.4664 13.9329L9.56452 8.03102L15.4664 2.12915L15.297 1.95978L15.4664 2.12915C15.8895 1.70608 15.8895 1.02016 15.4664 0.597096C15.0434 0.174015 14.3574 0.174015 13.9343 0.597096L8.03246 6.49897L2.13062 0.597092L6.50041 8.03102ZM0.598557 0.597087C1.02162 0.174017 1.70755 0.174018 2.13062 0.59709L0.598557 0.597087Z" fill="#0F0F0F" stroke="#0F0F0F" strokeWidth="0.5" />
                        </svg>
                    </button>
                    <div className='text-center w-100'>
                        <h1 className={style.modalheading}>Apply Now</h1>
                    </div>
                </Modal.Header>

                <Formik
                    initialValues={{
                        FirstName: "",
                        LastName: "",
                        Email: "",
                        MobileNo: "",
                        City: "",
                        ResumeOrCv: "",
                        Experience: "",
                        Position: "",
                        Joining: "",
                        Messages: ""
                    }}
                    validationSchema={APPLY_JOB_SCHEMA}
                    onSubmit={async (values, action) => {

                        Setloader(true)

                        const formData = new FormData();

                        formData.append("FirstName", values.FirstName)
                        formData.append("LastName", values.LastName)
                        formData.append("Email", values.Email)
                        formData.append("MobileNo", values.MobileNo)
                        formData.append("City", values.City)
                        formData.append("ResumeOrCv", values.ResumeOrCv)
                        formData.append("Experience", values.Experience)
                        formData.append("Position", values.Position)
                        formData.append("Joining", values.Joining)
                        formData.append("Messages", values.Messages)

                        values.Job_Information = selectedJobId;

                        axios.post("https://api.brainbinaryinfotech.com/applyjobnow/add", values, {
                            headers: { 'content-type': 'multipart/form-data' }
                        })
                            .then((res) => {
                                Setloader(false)
                                action.resetForm();
                                closeModal()
                                Swal.fire({
                                    position: "center",
                                    icon: "success",
                                    text: "Your Response will be submitted , and we will Contact you soon !",
                                    showConfirmButton: true,
                                    confirmButtonColor: 'forestgreen',
                                    confirmButtonText: 'OK , Got it !',
                                });
                            })
                            .catch((err) => {
                                console.log(err.response.data.message);
                                Setloader(false);
                            })

                    }}
                >
                    {
                        (props) => (
                            <Form>
                                <Modal.Body className='p-3 pe-4'>
                                    <div className='d-flex justify-content-center'>
                                        <div className={`w-100`}>
                                            <div className='d-lg-flex'>
                                                <div className={`mx-lg-4 my-lg-2 m-1 ${style.inputwidth}`}>
                                                    <label className={`${style.modalcontent} mb-2`}>First name</label>
                                                    <Field
                                                        type="text"
                                                        placeholder='Enter first name'
                                                        className={`${style.inputfield} form-control`}
                                                        name='FirstName'
                                                    />
                                                    <ErrorMessage name='FirstName' component={'span'} className='text-danger' />
                                                </div>
                                                <div className={`mx-lg-4 my-lg-2 m-1 ${style.inputwidth}`}>
                                                    <label className={`${style.modalcontent} mb-2`}>Last name</label>
                                                    <Field
                                                        type="text"
                                                        placeholder='Enter last name'
                                                        className={`${style.inputfield} form-control`}
                                                        name='LastName'
                                                    />
                                                    <ErrorMessage name='LastName' component={'span'} className='text-danger' />
                                                </div>
                                            </div>
                                            <div className='d-lg-flex'>
                                                <div className={`mx-lg-4 my-lg-2 m-1 ${style.inputwidth}`}>
                                                    <label className={`${style.modalcontent} mb-2`}>Email</label>
                                                    <Field
                                                        type="text"
                                                        placeholder='Enter email ID'
                                                        className={`${style.inputfield} form-control`}
                                                        name='Email'
                                                    />
                                                    <ErrorMessage name='Email' component={'span'} className='text-danger' />
                                                </div>
                                                <div className={`mx-lg-4 my-lg-2 m-1 ${style.inputwidth}`}>
                                                    <label className={`${style.modalcontent} mb-2`}>Mobile no.</label>
                                                    <Field
                                                        type="text"
                                                        placeholder='Enter mobile number'
                                                        className={`${style.inputfield} form-control`}
                                                        name='MobileNo'
                                                        maxLength={10}
                                                    />
                                                    <ErrorMessage name='MobileNo' component={'span'} className='text-danger' />
                                                </div>
                                            </div>
                                            <div className='d-lg-flex'>
                                                <div className={`mx-lg-4 my-lg-2 m-1 ${style.inputwidth}`}>
                                                    <label className={`${style.modalcontent} mb-2`}>City</label>
                                                    <Field
                                                        type="text"
                                                        placeholder='City'
                                                        className={`${style.inputfield} form-control`}
                                                        name='City'
                                                    />
                                                    <ErrorMessage name='City' component={'span'} className='text-danger' />
                                                </div>
                                                <div className={`mx-lg-4 my-lg-2 m-1 ${style.inputwidth}`}>
                                                    <label className={`${style.modalcontent} mb-2`}>Experience</label>
                                                    <Field as="select" name="Experience"
                                                        className={`${style.inputfield} form-control`}
                                                    >
                                                        <option value="">Select experience here</option>
                                                        <option value="Fresher">Fresher</option>
                                                        <option value="1 To 3 Years">1 To 3 years</option>
                                                        <option value="3 To 5 Years">3 To 5 years</option>
                                                        <option value="More Than 5 Years">More Than 5 year</option>
                                                    </Field>
                                                    <ErrorMessage name='Experience' component={'span'} className='text-danger' />
                                                </div>
                                            </div>
                                            <div className='d-lg-flex'>
                                                <div className={`mx-lg-4 my-lg-2 m-1 ${style.inputwidth}`}>
                                                    <label className={`${style.modalcontent} mb-2`}>Upload Resume / CV</label>
                                                    <div className={`${style.inputfield} custom-file form-control`}>
                                                        <input onChange={(e) => props.setFieldValue("ResumeOrCv", e.target.files[0])} type="file" name="ResumeOrCv" id="ResumeOrCv" accept=".pdf" />
                                                    </div>
                                                    <ErrorMessage name='ResumeOrCv' component={'span'} className='text-danger' />
                                                </div>
                                                <div className={`mx-lg-4 my-lg-2 m-1 ${style.inputwidth}`}>
                                                    <label className={`${style.modalcontent} mb-2`}>Select position here</label>
                                                    <Field as="select" name="Position"
                                                        className={`${style.inputfield} form-control`}>
                                                        <option value="">Select Position Here</option>
                                                        <option value="Junior">Junior</option>
                                                        <option value="Senior">Senior</option>
                                                        <option value="Team Leader">Team Leader</option>
                                                    </Field>
                                                    <ErrorMessage name='Position' component={'span'} className='text-danger' />
                                                </div>
                                            </div>
                                            <div className='d-lg-flex'>
                                                <div className={`mx-lg-4 my-lg-2 m-1 ${style.inputwidth}`}>
                                                    <label className={`${style.modalcontent} mb-2`}>Joining</label>
                                                    <Field as="select" name="Joining" className={`${style.inputfield} form-control`}
                                                    >
                                                        <option value="">Select joining</option>
                                                        <option value="Immediately">Immediately</option>
                                                        <option value="In a Week">In a Week</option>
                                                        <option value="In a Month">In a Month</option>
                                                    </Field>
                                                    <ErrorMessage name='Joining' component={'span'} className='text-danger' />
                                                </div>
                                                <div className={`mx-lg-4 my-lg-2 m-1 ${style.inputwidth}`}>
                                                    <label className={`${style.modalcontent} mb-2`}>Messages</label>
                                                    <Field
                                                        type="text"
                                                        placeholder='Message here'
                                                        className={`${style.inputfield} form-control`}
                                                        name='Messages'
                                                    />
                                                    <ErrorMessage name='Messages' component={'span'} className='text-danger' />

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className='border-0 pt-0 d-flex justify-content-center my-3'>
                                    <button type='submit' className={`btn ${style.submitbtn}`}>Submit</button>
                                </Modal.Footer>
                            </Form>
                        )
                    }
                </Formik>

            </Modal>
        </div>
    )
}

export default ApplyForJob;
